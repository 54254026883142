import { useDispatch } from "react-redux";
import { clearCredentials } from './authSlice';

export function LogoutButton () {
  const dispatch = useDispatch()

  function logout () {
    dispatch(clearCredentials())
  }

  return (
    <button
      type="button"
      onClick={logout}
    >Log out</button>
  )
}