import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { projectCoordinatorApi } from "../../constants/actionTypes";

// Create a new baseQuery for the new API
const newBaseQuery = fetchBaseQuery({
  baseUrl: projectCoordinatorApi,
  mode: 'cors',
  // prepareHeaders: (headers, { getState }) => {
  //   const token = getState().auth.token;
  //   token && headers.set('Authorization', `Bearer ${token}`);
  //   return headers;
  // },
});


const baseQueryWithReauth = async (args, api, extraOptions) => {
  // ... (existing code)
  const result = await newBaseQuery(args, api, extraOptions);
  // Check for errors in the new API response
  if (result.error?.status === 401) {
    // Handle errors from the new API
    // Add appropriate handling logic for the new API error codes
    console.log('Error from new API:', result.error);
    // Handle the error as per your requirement
  }

  return result;
};

export const newApi = createApi({
  reducerPath: 'newApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    // Define your new endpoint(s) here
    getQueueInfo: build.query({
      query: () => '',
    }),
  }),
});

export const { useGetQueueInfoQuery } = newApi;
