import React from 'react';
import { useSelector } from 'react-redux';
import { LogoutButton } from '../Auth/Logout';
import { FaUser } from 'react-icons/fa';
import CollaterateLogo from "../CollaterateLogo";

const Header = () => {
  const auth = useSelector((state) => state.auth);
  const user = `${auth.firstName} ${auth.lastName}`;

  return (
    <header className="bg-[#505050] ">
      <nav className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top navigation">
        <div className="flex items-center justify-between h-20">
          <div className="hidden md:flex items-center">
            <CollaterateLogo className="mx-auto h-12 w-auto" />
          </div>
          {/* Display user details */}
          <div className="flex items-center">
            <FaUser className="text-gray-300 mr-2" />
            <span className="text-gray-300 mr-2">Welcome, {user}</span>
            <span className="text-gray-300 ">&nbsp;|&nbsp;</span>
            <span className="text-gray-300 ml-2">
              <LogoutButton />
            </span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
