import moment from 'moment';

const isDate = (value) => {
  const date = moment(value, 'M/D/YY', true);
  return date.isValid();
};

const parsePrice = (priceString) => {
  return parseFloat(priceString.replace(/[$,]/g, ''));
};

const useSort = (rowsData, sortConfig) => {
  const sortedData = [...rowsData].sort((a, b) => {
    if (!sortConfig.key) return 0;

    const aValue = a.attributes[sortConfig.key] ?? '';
    const bValue = b.attributes[sortConfig.key] ?? '';

    const aIsDate = isDate(aValue);
    const bIsDate = isDate(bValue);

    if (aIsDate && bIsDate) {
      const aDate = moment(aValue, 'M/D/YY');
      const bDate = moment(bValue, 'M/D/YY');

      if (aDate.isBefore(bDate)) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aDate.isAfter(bDate)) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    } else if (aIsDate && !bIsDate) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    } else if (!aIsDate && bIsDate) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }

    const aNumericValue = (typeof aValue === 'string' && aValue.startsWith('$')) ? parsePrice(aValue) : aValue;
    const bNumericValue = (typeof bValue === 'string' && bValue.startsWith('$')) ? parsePrice(bValue) : bValue;

    const comparisonResult = aNumericValue.toString().localeCompare(bNumericValue.toString(), undefined, { numeric: true });

    if (comparisonResult < 0) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (comparisonResult > 0) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return sortedData;
};

export default useSort;
