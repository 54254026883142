import {Fragment, useEffect, useState} from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { getSelectedIdsForUser, updateSelectedIdsForUser } from "../../utils/storageUtils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dropdown = ({ className = "", label, placeholder = "All PCs", options = [], onChange, isClearFilter }) => {
  const auth = useSelector((state) => state.auth);
  const user = `${auth.firstName} ${auth.lastName}`;
  const [selectedIds, setSelectedIds] = useState(getSelectedIdsForUser(user));

  const handleItemClick = (id) => {
    if (onChange) {
      const updatedSelectedIds = selectedIds.includes(id)
        ? selectedIds.filter((selectedId) => selectedId !== id)
        : [...selectedIds, id];
      setSelectedIds(updatedSelectedIds);
      onChange(updatedSelectedIds);

      updateSelectedIdsForUser(user, updatedSelectedIds);
    }
  };

  const handleRemoveSelectedPc = (id) => {
    const updatedSelectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    setSelectedIds(updatedSelectedIds);
    onChange(updatedSelectedIds);
    updateSelectedIdsForUser(user, updatedSelectedIds);
  };

  const handleClearAll = () => {
    setSelectedIds([]);
    onChange([]);
    updateSelectedIdsForUser(user, []);
  };

  useEffect(() => {
    if (isClearFilter) {
      setSelectedIds([]);
      onChange([]);
    }
  }, [isClearFilter, onChange]);

  const getFilteredOptions = () => {
    return options.filter(({ PC }) => selectedIds.includes(PC));
  };

  const sortedOptions = [...options].sort((a, b) => a.PC.localeCompare(b.PC)); // Sort options alphabetically

  return (
    <Menu as="div" className={`relative inline-block text-left ${className}`}>
      {label && <div className="mb-1 text-sm">{label}</div>}
      <Menu.Button
        className="inline-flex justify-between rounded-md border border-gray-300 bg-white px-4 py-2 w-52 text-sm
        font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500
        focus:ring-offset-2 focus:ring-offset-gray-100"
      >
        {placeholder}
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1
        ring-black ring-opacity-5 focus:outline-none overflow-y-auto max-h-60"
        >
          <div className="py-1">
            {sortedOptions.map(({ user_id, PC }) => {
              const isActive = selectedIds.includes(PC);
              const handleClick = () => handleItemClick(PC);

              return (
                <Menu.Item key={PC}>
                  {({ active }) => (
                    <div
                      className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}
                      onClick={handleClick}
                    >
                      <input type="checkbox" checked={isActive} readOnly className="mr-2" />
                      {PC}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
      {selectedIds.length > 0 && (
        <div className="flex flex-wrap mb-4 mt-2">
          {getFilteredOptions().map(({ user_id, PC }) => (
            <div className="border-2 border-gray-600 mr-2 mb-2 p-2 rounded-lg flex justify-between" key={PC}>
              {PC}{" "}
              <span className="text-gray-500 cursor-pointer ml-4" onClick={() => handleRemoveSelectedPc(PC)}>
          <XCircleIcon className="h-4 w-4" aria-hidden="true" />
        </span>
            </div>
          ))}
          <button
            className="text-sm text-[#506986] cursor-pointer ml-2 underline hover:text-gray-700"
            onClick={handleClearAll}
          >
            Clear All
          </button>
        </div>
      )}
    </Menu>
  );
};

export default Dropdown;
