import { useField } from 'formik';
import Label from '../Label';
import InputErrorIcon from '../InputErrorIcon';
import InputErrorMessage from '../InputErrorMessage';
import ErrorTransition from '../ErrorTransition';
import Skeleton from "../../Skeleton";

const TextInput = ({ label, validate, ...props }) => {
    const [ field, meta ] = useField({ ...validate && { validate }, ...props });
    const hasError = Boolean(meta.touched && meta.error);

    return (
        <div>
            {label && <Label htmlFor={props.id || props.name} hasError>{label}</Label>}
            <div className="mt-1 relative">
                <input
                    type="text"
                    className={`block w-full rounded-md pr-10 py-2 pl-3 border shadow-sm sm:text-sm bg-white focus:outline-none focus:border-blue-500 focus:ring-blue-500 border-gray-300 ${hasError ? 'border-red-300 text-red-900 focus:border-red-500 placeholder-red-300 focus:ring-red-500' : ''}`}
                    {...field}
                    {...props}
                />
                {hasError && <InputErrorIcon />}
            </div>
            <ErrorTransition show={hasError}>
                <InputErrorMessage>{meta.error}</InputErrorMessage>
            </ErrorTransition>
        </div>
    );
};

export default TextInput;

export function TextInputLoader () {
    return (
        <div>
            <div className="h-5 w-44 rounded-full overflow-hidden"><Skeleton /></div>
            <div className="h-7 w-full mt-2 rounded-full overflow-hidden"><Skeleton /></div>
        </div>
    )
}
