export default function CollaterateLogo (props) {
    return (
        <svg className="h-2 w-2" {...props} stroke="none" fill="none" viewBox="0 0 512 512">
            <g>
                <g>
                    <path fill="#40C2CF" d="M254.97,212.802l0.009-0.012l0.1,0.1c0.269-0.31,0.484-0.631,0.741-0.934l49.489-49.493
                        c-15.104-18.702-20.497-42.787-1.798-61.483c30.207-30.208,62.207,1.077,84.498,23.368c23.37,23.37,48.547,55.737,19.419,84.865
                        c-0.825,0.825-1.675,1.535-2.521,2.265c16.896,5.929,33.613,16.137,49.989,30.54c51.629-56.689,43.485-128.129-10.788-182.396
                        C390.171,5.679,318.259-0.784,262.878,54.597c-22.95,22.948-39.122,48.959-41.876,76.634l-0.169,0.297
                        c-1.139,11.27-7.365,22.294-16.093,31.021l50.029,50.03C254.839,212.65,254.895,212.726,254.97,212.802z"/>
                </g>
                <g>
                    <path fill="#84D1DA" d="M212.802,254.79l-0.012-0.009l0.099-0.1c-0.31-0.269-0.63-0.484-0.934-0.741l-49.493-49.49
                        c-18.701,15.104-42.787,20.496-61.482,1.798c-30.208-30.208,1.076-62.207,23.367-84.498c23.371-23.37,55.737-48.547,84.865-19.419
                        c0.826,0.825,1.535,1.675,2.265,2.521c5.93-16.895,16.137-33.613,30.54-49.989C185.329,3.234,113.889,11.378,59.622,65.651
                        C5.679,119.588-0.784,191.5,54.597,246.882c22.948,22.949,48.959,39.121,76.634,41.875l0.298,0.17
                        c11.27,1.137,22.294,7.365,31.021,16.092l50.029-50.027C212.65,254.921,212.726,254.865,212.802,254.79z"/>
                </g>
                <g>
                    <path fill="#00829B" d="M257.03,299.198l-0.009,0.012l-0.099-0.1c-0.269,0.309-0.484,0.631-0.741,0.934l-49.489,49.492
                        c15.104,18.703,20.496,42.787,1.797,61.484c-30.207,30.207-62.207-1.078-84.497-23.367c-23.371-23.371-48.548-55.738-19.42-84.867
                        c0.826-0.824,1.675-1.533,2.521-2.264c-16.896-5.93-33.613-16.137-49.989-30.539C5.475,326.671,13.619,398.11,67.892,452.378
                        c53.937,53.943,125.85,60.406,181.23,5.025c22.949-22.949,39.121-48.959,41.875-76.635l0.169-0.297
                        c1.139-11.27,7.365-22.295,16.094-31.021l-50.029-50.031C257.161,299.349,257.106,299.274,257.03,299.198z"/>
                </g>
                <g>
                    <path fill="#009DB0" d="M299.197,255.718l0.012,0.009l-0.099,0.099c0.31,0.269,0.63,0.484,0.934,0.741l49.493,49.489
                        c18.701-15.104,42.787-20.496,61.482-1.799c30.208,30.209-1.076,62.207-23.367,84.498c-23.371,23.371-55.737,48.549-84.865,19.42
                        c-0.826-0.826-1.535-1.674-2.265-2.521c-5.93,16.896-16.137,33.613-30.539,49.99c56.688,51.629,128.127,43.484,182.394-10.789
                        c53.943-53.938,60.407-125.85,5.025-181.23c-22.947-22.949-48.959-39.121-76.634-41.875l-0.298-0.17
                        c-11.27-1.138-22.294-7.365-31.021-16.093l-50.029,50.03C299.35,255.586,299.273,255.642,299.197,255.718z"/>
                </g>
            </g>
        </svg>
    )
}
