import {useDispatch, useSelector} from 'react-redux';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import {selectCurrentUser} from './authSlice';
import { useRefreshMutation, useTokenFromSessionMutation } from "../Api/apiService"
import { useEffect, useState } from 'react';
import AppLoader from '../AppLoader/AppLoader';
import StandbyAlert from "../StandByAlert";


export default function ProtectedRoute () {
    const user = useSelector(selectCurrentUser);
    const location = useLocation();
    const [loginWithSession, { isLoading: isSessionLoading, isUninitialized: isSessionUninitialized }] = useTokenFromSessionMutation();
    const [loginWithRefresh, { isLoading: isRefreshLoading, isUninitialized: isRefreshUninitialized }] = useRefreshMutation();
    const [isLoading, setIsloading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsloading(
            user
                ? false
                : isSessionLoading || isRefreshLoading || isSessionUninitialized || isRefreshUninitialized
        )
    }, [user, isSessionLoading, isSessionUninitialized, isRefreshLoading, isRefreshUninitialized]);


    useEffect(() => {
        let aborted = false;
        let abortRefresh = null;
        let abortSession = null;

        (async function () {
            if (!user) {
                const gotToken = await getTokenFromRefresh();
                aborted || gotToken || getTokenFromSession();
            }
        }())

        async function getTokenFromRefresh () {
            try {
                const { abort, unwrap } = loginWithRefresh();
                abortRefresh = abort;
                await unwrap();
                abortRefresh = null;
                console.log('Got token from refresh cookie.');
                return true;
            } catch (e) {
                console.log('Could not get token from refresh cookie.');
                return false;
            }
        }

        async function getTokenFromSession () {
            try {
                const { abort, unwrap } = loginWithSession();
                abortSession = abort;
                await unwrap();
                abortSession = null;
                console.log('Got token from session cookie.');
            } catch (e) {
                console.log('Could not get token from session cookie.');
                handleSessionExpired();
            }
        };

        function handleSessionExpired() {
           return <StandbyAlert />;
        }

        return () => {
            aborted = true;
            abortRefresh && abortRefresh();
            abortSession && abortSession();
        }
    }, [user, loginWithRefresh, loginWithSession, dispatch]);

    return user
        ? <Outlet />
        : isLoading
            ? <AppLoader />
            : <Navigate to="/login" state={{ from: location }} replace />
}
