import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import API_URL from './API_URL.constant';
import {setCredentials, clearCredentials} from '../Auth/authSlice';
import { notificationAdded } from '../Notifications/notificationsSlice';
import {loginSuccess} from "../Auth/loginSlice";

const baseQuery = fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        token && headers.set('Authorization', `Bearer ${token}`);
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    const { user } = api.getState().auth;
    const result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        switch (result.error.status) {
          // Not auth'd or expired token
            case 401: {
                if (!user) {
                    break;
                }

                const refreshResult = await api.dispatch(templatesApi.endpoints.refresh.initiate());

                if (!refreshResult || refreshResult.error) {
                    api.dispatch(clearCredentials()); // TODO: should this invalidate the refresh token?
                    break;
                }

                return await baseQuery(args, api, extraOptions);
            }
          // Doesn't have permission
            case 403: {
                console.log(result.error)
                break;
            }
          // Swallow 404
            case 404: {
                console.log(result.error);
                break;
            }
          // Generic error handler
            default: {
                console.log('base query error:');
                console.log(result.error);

                if (result && result.error && result.error.data && result.error.data.errors) {
                    result.error.data.errors.forEach(error => {
                        api.dispatch(notificationAdded({
                            title: error.title,
                            message: error.detail,
                            type: 'warning',
                        }));
                    });
                } else {
                    api.dispatch(notificationAdded({
                        title: 'Uh-oh!',
                        message: result?.error?.data?.message,
                        type: 'warning',
                    }));
                }
            }
        }
    }

    return result;
};

export const templatesApi = createApi({
    reducerPath: 'templatesApi',
    tagTypes: ['Template', 'Site'],
    baseQuery: baseQueryWithReauth,
    endpoints: build => ({
        // Auth
        login: build.mutation({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                try {
                    const { username, password } = arg;

                    const response = await fetch(`${API_URL}/login`, {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        body: JSON.stringify({
                            username,
                            password,
                            supplierId: 1,
                        }),
                    }).then(r => {
                        return r.ok
                          ? r.json()
                          : { error: { data: { message: 'Login credentials incorrect. Please try again.' }}}
                    })

                    if (response.error) {
                        return response;
                    }
                    const user = { name: response.fullName };
                    api.dispatch(setCredentials({ token: response.apiToken }));
                    api.dispatch(loginSuccess(user));

                    return { data: null };
                } catch (err) {
                    console.log('login catch block')
                    console.log(err)
                    return { error: err };
                }

            }
        }),
        refresh: build.mutation({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                try {
                    const response = await fetch(`${API_URL}/refreshToken`, { headers: { 'content-type': 'application/json' }})
                      .then(r => {
                          return r.ok
                            ? r.json()
                            : { error: { data: { message: 'Could not refresh token.'}}}
                      });

                    if (response.error) {
                        return response;
                    }
                    api.dispatch(setCredentials({ token: response.apiToken }));
                    return { data: null };

                } catch (err) {
                    return { error: err };
                }
            }
        }),
        tokenFromSession: build.mutation({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                try {
                    const response = await fetch(`/rest/v1/supplierAdmin/adminUsers/jwtFromSession`, { headers: { 'content-type': 'application/json' }})
                      .then(r => {
                          return r.ok
                            ? r.json()
                            : { error: { data: { message: 'Could not get token from session.' }}}
                      });

                    if (response.error) {
                        return response;
                    }
                    api.dispatch(setCredentials({ token: response.apiToken }));
                    return { data: null };
                } catch (err) {
                    return { error: err };
                }
            }
        }),
    }),
});

export const {
    useLoginMutation,
    useRefreshMutation,
    useTokenFromSessionMutation
} = templatesApi;
