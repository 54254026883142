const SearchInput = ({value, onChange, onClear,placeholder}) => {
  return (
    <div className="flex items-center mr-2 mt-8">
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        pattern="[0-9]*"
        className="block w-36 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-medium placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-4"
      />
    </div>
  );
};

export default SearchInput;
