import React, { useState } from "react";
import moment from 'moment';
import useSort from "../Sorting";
import { HiDocumentCheck, HiCurrencyDollar } from "react-icons/hi2";
import { TiStarFullOutline } from "react-icons/ti";

const orderDetailsApi = process.env.REACT_APP_ORDER_DETAILS_API_ENDPOINT;

const Table = ({ className = "", currentPage, pageSize, appliedFilters, rowsData, columns, loading, error }) => {

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const handleSort = (columnId) => {
    const direction = sortConfig.key === columnId && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: columnId, direction });
  };

  const sortedData = useSort(rowsData, sortConfig).slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const columnColors = (column, value) => {

    if (value === 'Declined' || value === 'Canceled') {
      return 'bg-[#ffecf0] text-[#c82124]';
    } else if (value === 'Approved') {
      return 'bg-[#e0f5f0] text-[#007958]';
    } else if (value === 'On Hold') {
      return 'bg-[#ffe8cd] text-[#ec8100]';
    }

    if (column.id === 'ship_date') {
      const expectedDate = moment(value, 'M/D/YY');
      const currentDate = moment();
      if (expectedDate.isSame(currentDate, 'day')) {
        return 'bg-[#ffe8cd] text-[#ec8100]';
      }
      if (expectedDate.isBefore(currentDate, 'day')) {
        return 'bg-[#ffecf0] text-[#c82124]';
      }
    }
  }

  const rowColors = (row) => {
    const statusValue = row.attributes['flagged_for_prepress_alert'];
        if (statusValue === 1) {
            return 'bg-[#ffecf0] text-[#c82124]';
        }
  };

  let linkClicked = false;
  let pcqueueTab = null;

  function openLinkInTab(event, url) {
    event.preventDefault();
    if (!linkClicked || pcqueueTab === null || pcqueueTab.closed) {
      pcqueueTab = window.open(url, '_queue');
    } else {
      pcqueueTab.location.href = url;
    }
    linkClicked = true;
  }

  return (
    <>
      <div className="mt-4 mb-4 overflow-x-auto">
        <table className="border-collapse w-full border border-gray-300 bg-white text-sm shadow-sm whitespace-nowrap">
          <thead>
          <tr className="sticky top-0 border-t bg-gray-100 border-b-0">
            {columns.map((column) => (
              <th
                key={column.id}
                className={`w-auto border-0 hover:bg-blue-50 border-gray-300 border-r cursor-pointer 
                font-semibold py-1 pr-[5px] pl-[5px] ${column.headerAlign} ${sortConfig.key === column.id ? 'bg-blue-300' : ''}`}
                onClick={() => handleSort(column.id)}
              >
                {column.label}
                {sortConfig.key === column.id && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
            ))}
          </tr>
          </thead>
          {!loading && (
            <tbody>
            {sortedData.map((row, index) => {
              return (
                <tr key={index} tabIndex={-1} className={`hover:bg-blue-50 border-t border-b-0 ${rowColors(row)}`}>
                  {columns.map((column) => {
                    column.filterOptions = undefined;
                    const value = column.filterOptions
                      ? column?.filterOptions?.find((item) => item.value === row.attributes[column.id])?.label
                      : row.attributes[column.id];
                    const {component: Component} = column;
                    return (
                      <td
                        key={column.id}
                        className={`w-auto border-0 border-gray-500 font-normal ${columnColors(column, value)} py-1 ${column.align} pr-[5px] pl-[5px] 
                        ${column.label === "Product Type" || column.label === "Location" ? 'max-w-[100px] truncate overflow-hidden cursor-pointer' : ''}`}
                      >
                        {(() => {
                          if (column.label === "Product Type" || column.label === "Location") {
                            return (
                              <span title={value}>
                               {value}
                              </span>
                            );
                          }else if (column.label === "Order #") {
                            return (
                              <div className= {row.attributes.star === "1" ?"flex flex-row":" text-center"}>
                              {row.attributes.star === "1" && <TiStarFullOutline className="text-yellow-500 mt-1 w-6" /> }

                            <a
                                className="text-blue-800"
                                href={orderDetailsApi + row.attributes.order_id}
                                onClick={(event) =>
                                  openLinkInTab(event, orderDetailsApi + row.attributes.order_id)
                                }
                              >
                                {value}
                              </a>
                              </div>
                            )
                          } else if (column.label === "Job #") {
                            return (<a
                                    href={orderDetailsApi + row.attributes.order_id + "&JOB=" + value}
                                    onClick={(event) =>
                                        openLinkInTab(event, orderDetailsApi + row.attributes.order_id + "&JOB=" + value)
                                    }
                                >
                                  {value}
                                </a>
                            )
                          } else if (column.label === "Approved") {
                            if(value === "1") {
                              return (
                                <HiDocumentCheck
                                  className="w-6 h-6 bg-white-500 border-white text-green-600 inline"
                                />
                              );
                            }
                          } else if (column.label === "Price Flag") {
                            if(value === "true") {
                              return (
                                <HiCurrencyDollar
                                  className="w-6 h-6 bg-white-500 border-white text-[#ff9b00] inline"
                                />
                              );
                            }
                          } else {
                            return (Component ? (
                              <Component data={row} appliedFilters={appliedFilters}/>
                            ) : column.format && value ? (
                              column.format(value)
                            ) : (
                              value || "-"
                            ))
                          }
                        })()}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            </tbody>
          )}
        </table>
        {loading && <div className="p-2 text-center">
          Loading...
        </div>}
        {!loading && !error && sortedData.length === 0 && <div className="p-2 text-center">No Data Found</div>}
      </div>
    </>
  );
};

export default Table;
