import { AiOutlineLoading3Quarters } from "react-icons/ai";

function AbstractButton({ children, onClick, pending = false, block = false, className = '', ...props }) {
    return (
      <button
        type="button"
        onClick={onClick}
        {...props}
        className={`${block ? 'flex justify-center w-full' : 'inline-flex'} relative items-center px-4 py-2 border text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200 disabled:bg-gray-200 disabled:text-gray-500 ${className}`}
      >
          {pending && <AiOutlineLoading3Quarters className="animate-spin w-5 h-5 text-inherit opacity-70 mr-2" />}
          {children}
      </button>
    );
}

// Define the named exports
export const ButtonPrimary = ({ className = '', ...props }) => (
  <AbstractButton {...props} className={`text-white bg-blue-600 hover:bg-blue-700 border-transparent ${className}`} />
);

export const ButtonSecondary = ({ className = '', ...props }) => (
  <AbstractButton {...props} className={`text-gray-700 bg-white hover:bg-gray-50 border-gray-300 ${className}`} />
);

export const ButtonWarning = ({ className = '', ...props }) => (
  <AbstractButton {...props} className={`text-white bg-red-600 hover:bg-red-700 focus:ring-red-500 ${className}`} />
);
