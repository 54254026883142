import { createSlice, nanoid } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: [],
    reducers: {
        notificationAdded: {
            reducer: (state, { payload }) => {
                const { message, title, type = 'success', id } = payload;

                state.push({ message, title, type, id, active: true });
            },
            prepare: payload => ({
                payload: {
                    ...payload,
                    id: nanoid(),
                }
            })
        },
        notificationUpdated: (state, { payload }) => {
            const { id } = payload;
            const index = state.findIndex(notification => notification.id === id);

            if (index < 0) {
                return state;
            }

            Object.assign(state[index], payload);
        },
        notificationRemoved: (state, { payload }) => {
            const { id } = payload;
            state.splice(state.findIndex(notification => notification.id === id), 1);
        },
    },
});

export default notificationsSlice.reducer;

export const {
    notificationAdded,
    notificationUpdated,
    notificationRemoved,
} = notificationsSlice.actions;

export const selectNotifications = state => state.notifications;

// Use this hook to remove notifications,
// allows notification time to animate out prior to removal
export const useRemoveNotification = () => {
    const dispatch = useDispatch();

    return id => {
        dispatch(notificationUpdated({
            id,
            active: false,
        }));
    }
}
