export default function AppLoader () {
    return (
        <div className="w-full h-screen flex justify-center items-center" aria-label="Loading">
            <div className="dotPulse"></div>
        </div>
    )
}

/*

.wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    composes: dotPulse from 'css/animations.module.css';
}

*/
