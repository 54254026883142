import { Transition } from '@headlessui/react';

export default function ErrorTransition ({ children, show = false }) {
    return (
        <Transition
            show={show}
            enter="transition transform"
            enterFrom="opacity-0 -translate-x-20"
            enterTo="opacity-100 translate-x-0"
            leave="transition transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-20"
        >
            {children}
        </Transition>
    )
}
