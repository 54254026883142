export const getSelectedIdsForUser = (user) => {
  const storedData = JSON.parse(localStorage.getItem('pcData')) || {};

  if (!storedData.hasOwnProperty(user) || storedData[user].length === 0) {
    storedData[user] = [user];
  }
  localStorage.setItem('pcData', JSON.stringify(storedData));

  return storedData[user];
};

export const updateSelectedIdsForUser = (user, selectedIds) => {
  const storedData = JSON.parse(localStorage.getItem('pcData')) || {};
  storedData[user] = selectedIds;
  localStorage.setItem('pcData', JSON.stringify(storedData));
};
