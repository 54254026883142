import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { clearCredentials } from './Auth/authSlice';

function StandbyAlert() {
  const dispatch = useDispatch();
  const MAX_STANDBY_TIME_MS = 9 * 60 * 60 * 1000; // 9 hours

  const [showAlert, setShowAlert] = useState(false);
  const idleTimeoutIdRef = useRef(null);

  const handleStandbyAlert = useCallback(() => {
    // Use the ref to store the timeout ID
    idleTimeoutIdRef.current = setTimeout(() => {
      setShowAlert(true);
    }, MAX_STANDBY_TIME_MS);
  }, [MAX_STANDBY_TIME_MS]);

  const resetIdleTimeout = useCallback(() => {
    clearTimeout(idleTimeoutIdRef.current);
    handleStandbyAlert();
  }, [handleStandbyAlert]);

  const handleOkClick = () => {
    dispatch(clearCredentials());
  };

  useEffect(() => {
    const handleMouseMove = () => {
      resetIdleTimeout();
    };

    const handleKeyDown = () => {
      resetIdleTimeout();
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('keydown', handleKeyDown);
    handleStandbyAlert();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keydown', handleKeyDown);
      clearTimeout(idleTimeoutIdRef.current);
    };
  }, [dispatch, resetIdleTimeout, handleStandbyAlert]);

  return (
    showAlert && (
      <div className={`fixed inset-0 flex items-center justify-center z-50 ${showAlert ? 'backdrop-blur-sm' : ''}`}>
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <p>Your session has expired.</p>
          <button
            className="mt-2 bg-blue-500 hover-bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
            onClick={handleOkClick}
          >
            OK
          </button>
        </div>
      </div>
    )
  );
}

export default StandbyAlert;
