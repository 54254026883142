import { useEffect, useRef } from "react"

export default function InputErrorMessage ({ children, ...props }) {
    const message = useRef(children);

    useEffect(() => {
        message.current = children
            ? children
            : message.current;
    }, [children])

    return <p className="mt-2 text-sm text-red-600 whitespace-pre-line" {...props}>{message.current}</p>
}
