export const webSocketApi = process.env.REACT_APP_WEBSOCKET_API_ENDPOINT;
export const projectCoordinatorApi = process.env.REACT_APP_PROJECT_COORDINATOR_API_ENDPOINT;

export const addAndUpdateTableData = (data) => ({
    type: 'ADD_AND_UPDATE_DATA',
    payload: data
});

export const PAGE_LIMIT_OPTIONS = [
    {
        id: 100,
        value: 100,
    },
];

export const loginSuccess = (username) => ({
    type: 'text',
    payload: username
});
