import { createSlice } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        perms: null,
        token: null,
    },
    reducers: {
        setCredentials: (state, { payload }) => {
            const { token } = payload;
            // sub: username
            // perms: collaterate permissions
            // exp: expiration timestamp
            // iat: issued-at timestamp
            // iss: issuer
            // jti: token id
            // sess: not sure. collaterate realm?
            const { sub, perms, fname, lname  } = jwtDecode(token);

            state.user = sub;
            state.perms = perms;
            state.token = token;
            state.firstName = fname;
            state.lastName = lname;
        },
        clearCredentials: state => {
            state.user = null;
            state.perms = null;
            state.token = null;
            state.firstName = null;
            state.lastName = null;
        },
    },
});

export default authSlice.reducer;

export const { setCredentials, clearCredentials } = authSlice.actions;

export const selectCurrentUser = (state) => state.auth.user;
export const selectPermissions = (state) => state.auth.perms;
export const selectUserFullName = (state) => `${state.auth.firstName} ${state.auth.lastName}`;
