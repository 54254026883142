import { useEffect, useState } from "react";

import "./ToggleCardList.css";

const Widget = ({ options = [], activeTabId, }) => {
  const [active, setActive] = useState();

  const handleCardClick = (id, onClick) => {
    setActive(id);
    onClick && onClick(id);
  };

  useEffect(() => {
    if (options.length && activeTabId) setActive(activeTabId)
    else setActive(options[0].id);
  }, [options, activeTabId]);

  return (
    <div className="ToggleCardList flex space-x-4 mb-5 mt-5">
      {options.map(({ id, label, count, onClick }) => (
        <div
          key={id}
          onClick={() => handleCardClick(id, onClick)}
          className={`flex cursor-pointer items-center flex-col border-2 border-gray-700 px-5 pt-1 pb-2 w-48 ${
            active === id ? "active" : ""
          }`}
        >
          <div className="text-xs mb-2">{label}</div>
          <div className="text-4xl font-bold">{count}</div>
        </div>
      ))}
    </div>
  );
};

export default Widget;
