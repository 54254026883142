import { useLoginMutation } from "../Api/apiService"
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../Button/Button";
import CollaterateLogo from "../CollaterateLogo";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInput from "../Forms/TextInput/TextInput";
import { useDispatch } from "react-redux";
import { notificationAdded } from "../Notifications/notificationsSlice";


const validationSchema = Yup.object({
    username: Yup
        .string()
        .email('Please enter a valid email address.')
        .required('Email address is required to sign in.'),
    password: Yup
        .string()
        .required('Password is required to sign in.'),
});

export default function Login () {
    const [login] = useLoginMutation()
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();


    const from = location.state?.from?.pathname || '/';

    async function handleLogin (values) {
        try {
            await login(values).unwrap();
            navigate(from, { replace: true });
        } catch (e) {
            dispatch(notificationAdded({
                title: 'Uh-oh!',
                message: e.data.message,
                type: 'warning',
            }));
        }
    }

    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
        >{({ isSubmitting, dirty, isValid, values }) => (
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <CollaterateLogo className="mx-auto h-16 w-auto" />
                    <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">Sign in to your account</h2>
                </div>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Form className="space-y-6">
                            <TextInput label="Email" name="username" />
                            <TextInput label="Password" name="password" type="password" />
                            <div>
                                <ButtonPrimary
                                    type="submit"
                                    disabled={isSubmitting || !dirty || !isValid}
                                    pending={isSubmitting}
                                    block
                                >Sign in</ButtonPrimary>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        )}</Formik>
    )
}
