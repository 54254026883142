const initialState = {
    data: []
};

const tableData = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_AND_UPDATE_DATA':
      const updatedData = state.data.map(row => {
        if (row.attributes.id === action.payload[0].attributes.id) {
          return action.payload[0];
        } else {
          return row;
        }
      });

      const newData = updatedData.filter(row => !row.attributes.Flag);
      const newItems = action.payload.filter(item => !updatedData.some(y => y.attributes.id === item.attributes.id));

      return {
        ...state,
        data: [...newData, ...newItems],
      };

    default:
      return state;
  }
};

export default tableData;
